/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  Axios,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import axios from 'axios';
import type { DefaultQueryError } from '@/api/types';
import { apiUrl, API_FETCH_TIMEOUT } from './constants';

export const publicHttpClient = axios.create({
  baseURL: apiUrl,
  timeout: API_FETCH_TIMEOUT * 1000,
});

export type HttpClientConfig<D = any> = AxiosRequestConfig<D> & {
  tokenRequired?: boolean;
  cmsTokenRequired?: boolean;
  rawConfig?: HttpClientConfig;
  stringifyData?: boolean;
  _retry?: boolean;
};

interface HttpAxiosInstance extends Axios {
  (config: HttpClientConfig): AxiosPromise;
  (url: string, config?: HttpClientConfig): AxiosPromise;
  request<T = any, R = AxiosResponse<T>, D = any>(
    config: AxiosRequestConfig<D>
  ): Promise<R>;
  get<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: HttpClientConfig<D>
  ): Promise<R>;
  delete<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: HttpClientConfig<D>
  ): Promise<R>;
  post<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: HttpClientConfig<D>
  ): Promise<R>;
  put<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: HttpClientConfig<D>
  ): Promise<R>;
  patch<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: HttpClientConfig<D>
  ): Promise<R>;
}

export type HttpClientInstance = HttpAxiosInstance & {
  CancelToken: typeof axios.CancelToken;
  isCancel: typeof axios.isCancel;
};

const httpClient = axios.create({
  baseURL: apiUrl,
  // withCredentials: true,
  timeout: API_FETCH_TIMEOUT * 1000,
} as HttpClientConfig) as HttpClientInstance;

httpClient.defaults.headers.common['Content-Type'] = 'application/json';

httpClient.CancelToken = axios.CancelToken;
httpClient.isCancel = axios.isCancel;

export const installAxiosInterceptors = (instance: HttpClientInstance) => {
  // Interceptor request
  instance.interceptors.request.use(
    async (config) => await useOnFulfilledRequest(config),
    (error) => Promise.reject(error)
  );

  // Interceptor response
  instance.interceptors.response.use(
    (res) => res,
    async (error: DefaultQueryError) => await useOnRejectedResponse(error)
  );
};

export const useOnFulfilledRequest = async (config: HttpClientConfig) => {
  let token: string | undefined;

  if (config.cmsTokenRequired) {
    token = process.env.NEXT_PUBLIC_TOKEN_CMS;

    if (!token) {
      return {
        ...config,
        cancelToken: new axios.CancelToken((cancel) =>
          cancel('CMS_TOKEN_NOT_FOUND')
        ),
      } as HttpClientConfig;
    }
  }

  // if (!token) {
  // Cancel request if cannot get token
  // dispatchRequireAuth();

  //   return {
  //     ...config,
  //     cancelToken: new axios.CancelToken((cancel) =>
  //       cancel('USER_NOT_LOGIN_IN')
  //     ),
  //   } as HttpClientConfig;
  // }

  return updateAxiosConfig(config, token);
};

export const useOnRejectedResponse = async (error: DefaultQueryError) => {
  // const { config, response } = error;
  // const originalRequest = config as HttpClientConfig;

  // Normal flow: return error to caller
  return Promise.reject(error);
};

export function updateAxiosConfig(config: HttpClientConfig, token?: string) {
  config = { ...config };

  if (token) {
    // add token to header authorization
    config.headers = config.headers ?? {};
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}

installAxiosInterceptors(httpClient);

export default httpClient;
