import type { NextSeoProps } from 'next-seo';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { baseUrl } from '@/lib/constants';
import ButtonScrollTop from './ButtonScrollTop';
import Footer from './Footer';
import Header from './Header';

type ContainerProps = Omit<NextSeoProps, 'children'> & {
  children: ReactNode;
  blank?: boolean;
  canonical_url?: string;
};
export default function Container({
  children,
  blank = false,
  canonical_url,
  ...props
}: ContainerProps) {
  const { locale, asPath } = useRouter();
  const {
    title,
    description = locale === 'vi'
      ? 'Giao hàng siêu tốc xe máy - xe tải, an toàn, đáp ứng mọi nhu cầu.'
      : 'The ultimate solution for all delivery demands',
    openGraph,
    ...restProps
  } = props;

  const cleanPath = asPath.split('#')[0].split('?')[0];
  const url = `${baseUrl}` + (asPath === '/' ? '' : cleanPath);

  return (
    <>
      <NextSeo
        title={title}
        titleTemplate={`%s | Ahamove: Chuyên gia giao hàng, giao trong 1H`}
        defaultTitle="Ahamove: Chuyên gia giao hàng, giao trong 1H"
        description={description}
        canonical={canonical_url}
        openGraph={{
          url,
          type: 'website',
          site_name: process.env.NEXT_PUBLIC_APP_NAME,
          description,
          title: title || 'Ahamove: Chuyên gia giao hàng, giao trong 1H',
          images: [
            {
              url: `${baseUrl}/static/images/banner-tet-2025.png`,
              height: 627,
              width: 1200,
              alt: title || 'Ahamove: Chuyên gia giao hàng, giao trong 1H',
            },
          ],
          ...openGraph,
        }}
        twitter={{
          handle: '@ahamove',
          cardType: 'summary_large_image',
          site: '@ahamove',
        }}
        {...restProps}
      />
      {blank ? null : <Header />}
      {children}
      <ButtonScrollTop />
      {blank ? null : <Footer />}
    </>
  );
}
